.container {
  width: 100vw;
  overflow: hidden;
  height: 100vh;
}

.title {
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-zones-buttons {
  display: grid;
  grid-template-columns: repeat(27, 40px);
  grid-auto-rows: 40px;
  margin: 10px 0 0px;
  padding: 0 0 30px;
  gap: 2px;
  max-width: 100vw;
  overflow: auto;

  .time-zones-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid #666;
    border-radius: 3px;
    cursor: pointer;
    position: relative;

    .time-zones-button-name {
      position: absolute;
      bottom: -20px;
      font-size: 8px;
      text-align: center;
      line-height: 8px;
    }

    &:hover {
      background-color: #eee;
    }

    &.selected {
      background-color: #ccc;

      .time-zones-button-name {
        font-weight: bold;
      }
    }
  }
}

.time-zones {
  display: grid;
  grid-template-columns: 40px auto 40px;
  gap: 5px;
  height: calc(100vh - 130px);
  overflow: hidden;

  .time-zones-prev,
  .time-zones-next {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #eee;
    cursor: pointer;

    &:hover {
      background: #ccc;
    }
  }

  .time-zones-current {
    border: 0px solid #333;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    .time-zone-row {
      display: flex;
      flex-direction: column;

      .time-zone-name {
        display: flex;
        font-size: 0.75rem;
      }

      .time-zone-slots {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 30px;
        gap: 2px;
        margin-bottom: 2px;
        justify-content: center;
        position: relative;

        .time-zone-slot {
          font-size: 0.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;

          &.utc {
            font-weight: bold;
          }

          &.morning {
            background: #ccc;
            border: 1px solid #999;
          }

          &.afternoon {
            background: #fff;
            border: 1px solid #ccc;
          }

          &.evening {
            background: #ccc;
            border: 1px solid #999;
          }

          &.night {
            background: #666;
            border: 1px solid #333;
            color: #fff;
          }
        }
      }
    }
  }
}
